import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import gsap from "gsap";
import Navbar from '../../sections/Navbar';
import Footer from '../../sections/Footer';

import highlight_img from "../../../images/highlight_image.png"

const WebAppPenTesting = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isServicesHovered, setIsServicesHovered] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsServicesHovered(false);
      }
    }

    if (isServicesHovered) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isServicesHovered]);

  return (
    <div className="bg-dark text-white font-sans">

      <Navbar />

      {/* Hero Section */}
      <section className="hero-section overflow-hidden relative h-screen flex items-center justify-center bg-[#0E0E0E] bg-cover bg-center">

        {/* Top SVG Decoration */}
        <svg
          className="absolute -top-16 -left-32 w-[25%] sm:w-[20%] md:w-[15%] max-w-[457px]"
          viewBox="0 0 457 457"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.05">
            <path
              d="M199.748 42.464L104.73 78.2624C82.8318 86.4503 64.9326 112.347 64.9326 135.578V277.057C64.9326 299.527 79.7851 329.041 97.8747 342.561L179.754 403.684C206.603 423.869 250.779 423.869 277.628 403.684L359.507 342.561C377.597 329.041 392.449 299.527 392.449 277.057V135.578C392.449 112.157 374.55 86.2599 352.652 78.0719L257.634 42.464C241.449 36.5611 215.552 36.5611 199.748 42.464Z"
              stroke="#D1F60A"
              strokeWidth="28.5625"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M172.328 226.026L202.985 256.683L284.864 174.804"
              stroke="#D1F60A"
              strokeWidth="28.5625"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>

        {/* Background Glow Effects */}
        <div className="absolute -top-[10vh] right-[10vw] w-[70vw] h-[30vh] bg-[#D1F60A] rounded-full filter blur-[190px] opacity-40"></div>
        <div className="absolute -bottom-[10vh] -left-[10vw] w-[70vw] h-[30vh] bg-[#D1F60A] rounded-full filter blur-[190px] opacity-40"></div>

        {/* Content Area */}
        <div className="relative h-full w-full overflow-hidden flex items-center justify-center">

          <h1 className="text-3xl text-center sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-4 leading-tight max-w-[90%] sm:max-w-[56rem] text-[#D1F60A]">
            Web Services <br /> and API Assessment
          </h1>

          <svg
            className="absolute top-[60vh] w-[80%] sm:w-[40%] md:w-[45%] max-w-[480px] h-auto"
            viewBox="0 0 457 457"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid meet"
          >
            <path
              d="M199.748 42.464L104.73 78.2624C82.8318 86.4503 64.9326 112.347 64.9326 135.578V277.057C64.9326 299.527 79.7851 329.041 97.8747 342.561L179.754 403.684C206.603 423.869 250.779 423.869 277.628 403.684L359.507 342.561C377.597 329.041 392.449 299.527 392.449 277.057V135.578C392.449 112.157 374.55 86.2599 352.652 78.0719L257.634 42.464C241.449 36.5611 215.552 36.5611 199.748 42.464Z"
              stroke="white"
              strokeWidth="28.5625"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M172.328 226.026L202.985 256.683L284.864 174.804"
              stroke="white"
              strokeWidth="28.5625"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          {/* Center Background Glow */}
          <div className="absolute top-[-35vh] sm:bottom-[-20vh] h-[30vh] w-[30vh] sm:h-[40vh] sm:w-[40vh] md:h-[52vh] md:w-[52vh] bg-[#D1F60A] blur-[120px] sm:blur-[150px] md:blur-[170px]"></div>

        </div>

      </section>

      <section className="min-h-screen flex flex-col items-center justify-center py-24 bg-[#0E0E0E] bg-cover bg-center">

        <div className="w-full max-w-7xl px-6 sm:px-8">
          {/* Heading */}
          <h2 className="text-3xl sm:text-4xl lg:text-5xl font-semibold text-center text-white mb-8">
            What we cover in this <span className="text-[#D1F60A]">area!</span>
          </h2>

          {/* Subheading */}
          <h3 className="text-2xl lg:text-3xl font-semibold text-center text-gray-300 mb-12">
            Web Services and <span className="text-[#D1F60A]">API</span> Assessment
          </h3>
        </div>

        {/* Cards Container */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 px-4 sm:px-8">
          {/* Card 1 */}
          <div className="h-[400px] w-full sm:w-[300px] bg-[#d3f60a2d] rounded-[12px] p-6 flex flex-col">
            <h4 className="text-xl lg:text-2xl font-bold mb-4 text-white">API Authentication Testing</h4>
            <p className="text-base lg:text-lg text-gray-300">
              We assess the security of your API’s authentication methods, ensuring proper implementation of protocols such as OAuth and JWT to prevent unauthorized access.
            </p>
          </div>

          {/* Card 2 */}
          <div className="h-[400px] w-full sm:w-[300px] bg-[#d3f60a2d] rounded-[12px] p-6 flex flex-col">
            <h4 className="text-xl lg:text-2xl font-bold mb-4 text-white">Data Validation and Injection</h4>
            <p className="text-base lg:text-lg text-gray-300">
              We test for common vulnerabilities like SQL injections, XML injection, and other data validation issues, which could expose your API to external attacks.
            </p>
          </div>

          {/* Card 3 */}
          <div className="h-[400px] w-full sm:w-[300px] bg-[#d3f60a2d] rounded-[12px] p-6 flex flex-col">
            <h4 className="text-xl lg:text-2xl font-bold mb-4 text-white">Rate Limiting and Throttling</h4>
            <p className="text-base lg:text-lg text-gray-300">
              We analyze your API’s rate limiting and throttling measures to ensure protection against brute-force attacks and API abuse.
            </p>
          </div>

          {/* Card 4 */}
          <div className="h-[400px] w-full sm:w-[300px] bg-[#d3f60a2d] rounded-[12px] p-6 flex flex-col">
            <h4 className="text-xl lg:text-2xl font-bold mb-4 text-white">API Response Security</h4>
            <p className="text-base lg:text-lg text-gray-300">
              We test the security of API responses to prevent issues like information leakage and ensure data is properly encrypted and sanitized before being sent to users.
            </p>
          </div>
        </div>

      </section>

      <section className="min-h-screen overflow-hidden relative flex flex-col items-center justify-center py-24 bg-[#0E0E0E] bg-cover bg-center">
        {/* Container to limit width */}
        <div className="max-w-7xl px-6 w-full">
          {/* Heading */}
          <h2 className="text-3xl sm:text-4xl lg:text-5xl font-semibold text-center text-white mb-8">
            Ensure <span className="text-[#D1F60A]">API security</span> in every corner of <br /> your organization
          </h2>

          {/* Paragraph */}
          <p className="text-lg sm:text-xl lg:text-2xl font-semibold text-center text-gray-300 mb-12">
            APIs make it simpler for companies and teams to utilize the functionality provided by you, without having to develop it themselves. However, since your APIs are exposed, if there’s a vulnerability, it means that potentially every application that makes use of your API is affected. This type of API security loophole can end up damaging your reputation. We deploy different types of tools that attackers use, which helps us ensure that your API is tested from the perspective of a real-time attacker.
          </p>
        </div>

      </section>

      <section className="min-h-screen flex flex-col items-center justify-center py-24 bg-[#0E0E0E] bg-cover bg-center">

        <div className="w-full max-w-7xl px-6 sm:px-8">
          {/* Heading */}
          <h2 className="text-3xl sm:text-4xl lg:text-5xl font-semibold text-left text-white mb-4">
            What to <span className="text-[#D1F60A]">expect!</span>
          </h2>

          {/* Subheading */}
          <h3 className="text-2xl lg:text-3xl font-semibold text-left text-gray-300 mb-12">
            Here’s what you can look forward to when <span className="text-[#D1F60A]">you choose us.</span>
          </h3>
        </div>

        {/* Cards Container */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 px-4 sm:px-8">
          {/* Card 1 */}
          <div className="h-[250px] w-full sm:w-[300px] bg-[#d3f60a2d] border-b-2 border-[#D1F60A] flex flex-col justify-end p-6 transform transition-all duration-300 hover:scale-105">
            <h4 className="text-3xl sm:text-2xl lg:text-2xl font-bold text-white">Comprehensive Web Services & API Testing</h4>
          </div>

          {/* Card 2 */}
          <div className="h-[250px] w-full sm:w-[300px] bg-[#d3f60a2d] border-b-2 border-[#D1F60A] flex flex-col justify-end p-6 transform transition-all duration-300 hover:scale-105">
            <h4 className="text-3xl sm:text-2xl lg:text-2xl font-bold text-white">Detailed Security Analysis of Web Services</h4>
          </div>

          {/* Card 3 */}
          <div className="h-[250px] w-full sm:w-[300px] bg-[#d3f60a2d] border-b-2 border-[#D1F60A] flex flex-col justify-end p-6 transform transition-all duration-300 hover:scale-105">
            <h4 className="text-3xl sm:text-2xl lg:text-2xl font-bold text-white">API Vulnerability Scanning & Assessment</h4>
          </div>

          {/* Card 4 */}
          <div className="h-[250px] w-full sm:w-[300px] bg-[#d3f60a2d] border-b-2 border-[#D1F60A] flex flex-col justify-end p-6 transform transition-all duration-300 hover:scale-105">
            <h4 className="text-3xl sm:text-2xl lg:text-2xl font-bold text-white">In-depth Reports & Actionable Insights</h4>
          </div>
        </div>

      </section>

      <section className="min-h-screen flex flex-col items-center justify-center py-24 bg-[#0E0E0E] bg-cover bg-center">
        <div className="w-full max-w-7xl px-6 sm:px-8">
          {/* Heading */}
          <h2 className="text-3xl sm:text-4xl lg:text-5xl font-semibold text-center text-white mb-4">
            Methodology
          </h2>

          {/* Subheading */}
          <h3 className="text-2xl lg:text-3xl font-semibold text-center text-gray-300 mb-12">
            Hybrid Approach to <span className="text-[#D1F60A]">Web Services and API Assessment</span>
          </h3>
        </div>

        {/* Cards Container */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8 px-4 sm:px-8">
          {/* Card 1 */}
          <div className="h-[250px] w-[250px] bg-[#d3f60a2d] rounded-lg flex flex-col justify-center p-6 transform transition-all duration-300 hover:scale-105">
            <span className="text-4xl text-[#D1F60A] font-bold mb-2 text-left">01</span>
            <h4 className="text-xl font-bold text-white text-left">API Endpoint Mapping</h4>
          </div>

          {/* Card 2 */}
          <div className="h-[250px] w-[250px] bg-[#d3f60a2d] rounded-lg flex flex-col justify-center p-6 transform transition-all duration-300 hover:scale-105">
            <span className="text-4xl text-[#D1F60A] font-bold mb-2 text-left">02</span>
            <h4 className="text-xl font-bold text-white text-left">Security Test Case Development</h4>
          </div>

          {/* Card 3 */}
          <div className="h-[250px] w-[250px] bg-[#d3f60a2d] rounded-lg flex flex-col justify-center p-6 transform transition-all duration-300 hover:scale-105">
            <span className="text-4xl text-[#D1F60A] font-bold mb-2 text-left">03</span>
            <h4 className="text-xl font-bold text-white text-left">Automated Vulnerability Scanning</h4>
          </div>

          {/* Card 4 */}
          <div className="h-[250px] w-[250px] bg-[#d3f60a2d] rounded-lg flex flex-col justify-center p-6 transform transition-all duration-300 hover:scale-105">
            <span className="text-4xl text-[#D1F60A] font-bold mb-2 text-left">04</span>
            <h4 className="text-xl font-bold text-white text-left">Manual Testing & Exploitation</h4>
          </div>

          {/* Card 5 */}
          <div className="h-[250px] w-[250px] bg-[#d3f60a2d] rounded-lg flex flex-col justify-center p-6 transform transition-all duration-300 hover:scale-105">
            <span className="text-4xl text-[#D1F60A] font-bold mb-2 text-left">05</span>
            <h4 className="text-xl font-bold text-white text-left">Comprehensive Reporting</h4>
          </div>
        </div>
      </section>


      <Footer />

    </div>
  );
};

export default WebAppPenTesting;