import React from 'react';
import ServicesCard from '../Services/ServicesCard';

const CloudSecurity = () => {
  const services = [
    {
      title: 'Cloud Auditing',
      subtitle: 'Assess your cloud for security compliance',
      route: '/cloud-security/cloud-auditing',
    },
    {
      title: 'Cloud Penetration Testing',
      subtitle: 'Simulate attacks to identify vulnerabilities',
      route: '/cloud-security/cloud-penetration-testing',
    },
    // Add more services and routes as needed
  ];

  return (
    <div className="p-6 bg-[#121212] min-h-screen">
      <h1 className="text-2xl font-bold text-white mb-6">Cloud Security Services</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {services.map((service, index) => (
          <ServicesCard
            key={index}
            title={service.title}
            subtitle={service.subtitle}
            route={service.route}
          />
        ))}
      </div>
    </div>
  );
};

export default CloudSecurity;
