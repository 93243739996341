import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./components/pages/LandingPage/LandingPage"
import WebAppPenTesting from "./components/pages/applicationSecurity/WebAppPenTesting"
import MobileAppPentesting from "./components/pages/applicationSecurity/MobileAppPentesting";
import ApiAssessment from "./components/pages/applicationSecurity/ApiAssessment";
import ThreatModeling from "./components/pages/applicationSecurity/ThreatModeling";
import SecureCodeReview from "./components/pages/applicationSecurity/SecureCodeReview";
import NotFound from "./components/pages/NotFound";
import ApplicationArchitectureReview from "./components/pages/applicationSecurity/ApplicationArchitectureReview";
import ContactUs from "./components/pages/ContactUs/ContactUs";
import Services from "./components/pages/Services/Services";
import AIModelPenTest from "./components/pages/aiMlSecurity/AIModelPenTest"
import LlmSecurity from "./components/pages/aiMlSecurity/LlmSecurity";
import AdversarialAttack from "./components/pages/aiMlSecurity/AdversarialAttack";
import ModelExplainability from "./components/pages/aiMlSecurity/ModelExplainability";
import CloudAuditing from "./components/pages/CloudSecurity/CloudAuditing";
import CloudPentesting from "./components/pages/CloudSecurity/CloudPentesting";
import SmartContractAuditing from "./components/pages/Web3Services/SmartContractAuditing";
import DefiApplicationTesting from "./components/pages/Web3Services/DefiApplicationTesting";
import BlockChainSecurity from "./components/pages/Web3Services/BlockChainSecurity";
import WalletSecurity from "./components/pages/Web3Services/WalletSecurity";
import VulnerabilityAssess from "./components/pages/networkSecurity/VulnerabilityAssess";
import DeviceSecurity from "./components/pages/networkSecurity/DeviceSecurity";
import VoipTesting from "./components/pages/networkSecurity/VoipTesting";
import WirelessPenTest from "./components/pages/networkSecurity/WirelessPenTest";
import AboutUs from "./components/pages/aboutUs/AboutUs";
import ApplicationSecurity from "./components/pages/Services/ApplicationSecurity";
import AIMLSecurity from "./components/pages/Services/AIMLSecurity";
import CloudSecurity from "./components/pages/Services/CloudSecurity";
import Web3Security from "./components/pages/Services/Web3Security";
import NetworkSecurity from "./components/pages/Services/NetworkSecurity";
import Trainings from "./components/pages/Training/Trainings";

const AllRoutes = () => {
  return (
    <>
      <Router>
        <Routes>

          {/* Route for the landing page */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/trainings" element={<Trainings />} />


          {/* Services Routes */}
          <Route path="/application-security" element={<ApplicationSecurity />} />
          <Route path="/ai-ml-security" element={<AIMLSecurity />} />
          <Route path="/cloud-security" element={<CloudSecurity />} />
          <Route path="/web3-security" element={<Web3Security />} />
          <Route path="/network-security" element={<NetworkSecurity />} />

          {/* Application Security Routes */}
          <Route path="/application-security/web-app-penetration-testing" element={<WebAppPenTesting />} />
          <Route path="/application-security/mobile-app-penetration-testing" element={<MobileAppPentesting />} />
          <Route path="/application-security/api-assessment" element={<ApiAssessment />} />
          <Route path="/application-security/threat-modeling" element={<ThreatModeling />} />
          <Route path="/application-security/secure-code-review" element={<SecureCodeReview />} />
          <Route path="/application-security/architecture-review" element={< ApplicationArchitectureReview/>} />

          {/* AI / ML Security Routes */}
          <Route path="/ai-ml-security/ai-model-pen-testing" element={<AIModelPenTest />} />
          <Route path="/ai-ml-security/llm-security-assessment" element={<LlmSecurity />} />
          <Route path="/ai-ml-security/adversarial-attacks" element={<AdversarialAttack />} />
          <Route path="/ai-ml-security/model-explainability" element={<ModelExplainability />} />

          {/* Cloud Security Routes */}
          <Route path="/cloud-security/cloud-auditing" element={<CloudAuditing />} />
          <Route path="/cloud-security/cloud-penetration-testing" element={<CloudPentesting />} />

          {/* Web3 Security Routes */}
          <Route path="/web3-security/smart-contract-auditing" element={<SmartContractAuditing />} />
          <Route path="/web3-security/blockchain-security" element={<BlockChainSecurity />} />
          <Route path="/web3-security/defi-application-testing" element={<DefiApplicationTesting />} />
          <Route path="/web3-security/wallet-security" element={<WalletSecurity />} />

          {/* Network Security Routes */}
          <Route path="/network-security/vulnerability-assessment" element={<VulnerabilityAssess />} />
          <Route path="/network-security/device-security" element={<DeviceSecurity />} />
          <Route path="/network-security/voip-testing" element={<VoipTesting />} />
          <Route path="/network-security/wireless-penetration-testing" element={<WirelessPenTest />} />

          <Route path="*" element={<NotFound/>} />
          

        </Routes>
      </Router>
    </>
  );
};

export default AllRoutes;