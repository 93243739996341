import React from 'react';
import { Link } from 'react-router-dom';

const Card = ({ title, subtitle, route }) => {
  return (
    <Link
      to={route}
      className="flex items-center space-x-6 hover:bg-[#1D1D1D] p-4 rounded-lg shadow-md transform hover:scale-105 transition-transform duration-200"
    >
      <div className="rounded-[12px] w-20 h-20 flex-shrink-0 flex items-center justify-center bg-[#353C0D]">
        <p className="text-xl font-semibold text-[#D1F60A]">
          {title.slice(0, 3)}
        </p>
      </div>
      <div>
        <p className="text-xl font-bold text-white ">{title}</p>
        <p className="text-sm text-gray-400">{subtitle}</p>
      </div>
    </Link>
  );
};

export default Card;
