import React from 'react';
import Card from '../Services/ServicesCard'; // Assuming Card is in the same folder

const NetworkSecurity = () => {
  const services = [
    {
      title: 'Vulnerability Assessment',
      subtitle: 'Identify and mitigate network vulnerabilities',
      route: '/network-security/vulnerability-assessment',
    },
    {
      title: 'Device Security',
      subtitle: 'Secure your devices from external threats',
      route: '/network-security/device-security',
    },
    {
      title: 'VoIP Testing',
      subtitle: 'Test your VoIP systems for potential risks',
      route: '/network-security/voip-testing',
    },
    {
      title: 'Wireless Penetration Testing',
      subtitle: 'Evaluate wireless network vulnerabilities',
      route: '/network-security/wireless-penetration-testing',
    },
  ];

  return (
    <div className="p-6 bg-[#121212] min-h-screen">
      <h1 className="text-2xl font-bold text-white mb-6">Network Security Services</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {services.map((service, index) => (
          <Card
            key={index}
            title={service.title}
            subtitle={service.subtitle}
            route={service.route}
          />
        ))}
      </div>
    </div>
  );
};

export default NetworkSecurity;
