import React from 'react';
import Card from '../Services/ServicesCard'; // Assuming Card is in the same folder

const AIMLSecurity = () => {
  const services = [
    {
      title: 'AI Model Pen Testing',
      subtitle: 'Assess the robustness of your AI models',
      route: '/ai-ml-security/ai-model-pen-testing',
    },
    {
      title: 'LLM Security Assessment',
      subtitle: 'Evaluate large language models for vulnerabilities',
      route: '/ai-ml-security/llm-security-assessment',
    },
    {
      title: 'Adversarial Attacks',
      subtitle: 'Test resistance against adversarial inputs',
      route: '/ai-ml-security/adversarial-attacks',
    },
    {
      title: 'Model Explainability',
      subtitle: 'Ensure transparency and trust in AI systems',
      route: '/ai-ml-security/model-explainability',
    },
  ];

  return (
    <div className="p-6 bg-[#121212] min-h-screen">
      <h1 className="text-2xl font-bold text-white mb-6">AI / ML Security Services</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {services.map((service, index) => (
          <Card
            key={index}
            title={service.title}
            subtitle={service.subtitle}
            route={service.route}
          />
        ))}
      </div>
    </div>
  );
};

export default AIMLSecurity;
