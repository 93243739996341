import React from 'react';
import Card from '../Services/ServicesCard'; // Assuming Card is in the same folder

const Web3Security = () => {
  const services = [
    {
      title: 'Smart Contract Auditing',
      subtitle: 'Ensure your smart contracts are secure and efficient',
      route: '/web3-security/smart-contract-auditing',
    },
    {
      title: 'Blockchain Security',
      subtitle: 'Protect your blockchain infrastructure',
      route: '/web3-security/blockchain-security',
    },
    {
      title: 'DeFi Application Testing',
      subtitle: 'Secure your decentralized finance applications',
      route: '/web3-security/defi-application-testing',
    },
    {
      title: 'Wallet Security',
      subtitle: 'Enhance the security of your cryptocurrency wallets',
      route: '/web3-security/wallet-security',
    },
  ];

  return (
    <div className="p-6 bg-[#121212] min-h-screen">
      <h1 className="text-2xl font-bold text-white mb-6">Web3 Security Services</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {services.map((service, index) => (
          <Card
            key={index}
            title={service.title}
            subtitle={service.subtitle}
            route={service.route}
          />
        ))}
      </div>
    </div>
  );
};

export default Web3Security;
