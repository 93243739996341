import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

import section_img_3d from "../../../images/3D_section_img.png"
import highlight_img from "../../../images/highlight_image.png"
import aboutus from "../../../images/aboutus.png"
import cloud_image from "../../../images/cloud_img.png"
import network_image from "../../../images/network_img.png"
import web3_image from "../../../images/web3_img.png"

import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { title } from 'framer-motion/client';
import { Navigate } from 'react-router-dom';
import Footer from '../../sections/Footer';
import Navbar from '../../sections/Navbar';
import ContactUs from '../ContactUs/ContactUs'

const AboutUs = () => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isServicesHovered, setIsServicesHovered] = useState(false);
    const dropdownRef = useRef(null);
    const [abtUsSectionHovered, setAbtUsSectionHovered] = useState(false);

    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {

        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsServicesHovered(false);
            }
        }

        if (isServicesHovered) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isServicesHovered]);

    useEffect(() => {
        // Create a new Intersection Observer
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Animate the card when it comes into view
                    gsap.from(entry.target, {
                        y: 100,  // Start 100px below
                        opacity: 0,  // Start fully transparent
                        duration: 1,  // Animation duration
                        ease: "power3.out",  // Ease type
                    });
                    // Stop observing the card once it's animated
                    observer.unobserve(entry.target);
                }
            });
        }, { threshold: 0.1 });

        // Select all cards and observe them
        const cards = document.querySelectorAll('.card');
        cards.forEach((card) => observer.observe(card));

        return () => {
            // Clean up observer on unmount
            observer.disconnect();
        };
    }, []);

    const abtUsSections = [
        { title: "About Us", subtitle: "Your Trusted Cybersecurity Experts", description: "With over a decade of experience in the field of Information Security, our team is dedicated to delivering world-class cybersecurity services. We have partnered with multinational corporations (MNCs) and government entities across the Middle East, showcasing our expertise in safeguarding critical systems." },

        { title: "Our Elite Team", subtitle: "Hackers, Security Researchers, and Innovators", description: "Our team comprises some of the most skilled and accomplished professionals in the cybersecurity industry. From ethical hackers and security researchers to bug bounty hunters, we have individuals who have uncovered thousands of vulnerabilities, earning global recognition and accolades." },

        { title: "Global Recognitions", subtitle: "Hall of Fame Listings and Bounty Awards", description: "Our achievements speak for themselves. Members of our team have received Hall of Fame recognitions and bounty awards from leading tech giants such as Apple, PayPal, Meta, Microsoft, Google, and many others. These accolades reflect our commitment to excellence and our ability to think like attackers to protect your assets." },

        { title: "A Proven Track Record", subtitle: "Protecting Public and Private Sectors", description: "From collaborating with government organizations to securing private companies, we have a proven track record of success. Our comprehensive approach ensures the highest levels of protection for businesses of all sizes, safeguarding sensitive data and critical infrastructure." }
    ];

    // ---------------------------- Hover Data ---------------------------- 

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        gsap.fromTo(
            ".hero-title",
            { opacity: 0, x: -100 },  // Start with opacity 0 and off-screen to the left
            {
                opacity: 1,
                x: 0,  // Move to its normal position
                duration: 1.5,  // Slower fade-in effect
                ease: "power2.out",  // Smooth easing
            }
        );

        // Optional: Add a subtle hover effect
        gsap.to(".hero-title", {
            opacity: 0.3,  // Slight opacity change on hover for a classy touch
            duration: 0.3,
            paused: true,
            yoyo: true,
            ease: "power1.inOut",
            onHover: true,
        });
    }, []);


    useEffect(() => {
        // Animate the hero title
        gsap.fromTo(
            ".hero-title",
            { opacity: 0, x: -100 },  // Start with opacity 0 and off-screen to the left
            {
                opacity: 1,
                x: 0,  // Move to its normal position
                duration: 1.5,  // Slower fade-in effect
                ease: "power2.out",  // Smooth easing
            }
        );

        // Animate the blurred background div
        gsap.fromTo(
            ".hero-bg",
            { opacity: 1, y: 300 },  // Start with opacity 0 and below the screen
            {
                opacity: 1,
                y: -100,  // Move to its normal position
                duration: 1.5,  // Slower movement to match the hero-title animation
                ease: "power2.out",  // Smooth easing
            }
        );

        const tl = gsap.timeline();

        // Step 1: Element comes from below
        tl.fromTo(
            ".bg_shield",
            { opacity: 0, y: 450 },
            {
                opacity: 1,
                y: 0,
                duration: 1.5,
                ease: "power2.out",
            }
        );

        // Step 2: Continuous up-and-down animation
        tl.to(".bg_shield", {
            y: 50, // Moves 50px down
            duration: 2,
            ease: "power1.inOut",
            repeat: -1, // Infinite repeat
            yoyo: true, // Reverses the animation
        });



        // Optional: Add a subtle hover effect to hero title
        gsap.to(".hero-title", {
            opacity: 0.3,  // Slight opacity change on hover for a classy touch
            duration: 0.3,
            paused: true,
            yoyo: true,
            ease: "power1.inOut",
            onHover: true,
        });
    }, []);


    return (

        <div className="bg-dark text-white font-sans overflow-hidden ">

            <Navbar />

            {/* About Us Section */}
            <section className="py-40 lg:py-44 bg-[#0E0E0E]">
                <div className="container max-w-screen-xl mx-auto flex flex-col lg:flex-row items-center lg:justify-between lg:px-6 px-4 text-left">

                    <div className="lg:w-3/5 w-full">

                        {abtUsSections.map((section, index) => (

                            <div
                                className="relative group"
                                onMouseEnter={() => setAbtUsSectionHovered(index)}
                                onMouseLeave={() => setAbtUsSectionHovered(null)}
                            >
                                <div className={`transition-all duration-700 ${abtUsSectionHovered === index ? 'h-auto' : 'h-24'}`}>
                                    <h3 className="text-2xl font-semibold text-white">{section.title}</h3>
                                    <h4 className="text-lg font-medium text-[#D1F60A] mt-2">{section.subtitle}</h4>
                                </div>

                                <div
                                    className={`overflow-hidden transition-all duration-700 ease-in-out max-h-0 ${abtUsSectionHovered === index ? 'max-h-[500px]' : ''}`}
                                >
                                    {abtUsSectionHovered === index && (
                                        <div className="mt-4 opacity-100 transition-opacity duration-700 ease-in-out">
                                            <p className="text-base text-[#8b8b8b]">{section.description}</p>
                                        </div>
                                    )}
                                </div>

                                <div
                                    className={`border-b-2 border-transparent transition-all duration-700 mt-4 mb-4 ${abtUsSectionHovered === index ? 'border-[#D1F60A]' : 'border-transparent'}`}
                                ></div>
                            </div>
                        ))}

                    </div>

                    <div className="lg:w-2/5 w-full mt-8 lg:mt-0">

                        <img src={aboutus} />

                    </div>

                </div>
            </section>

            <Footer />

        </div >
    );
};

export default AboutUs;
