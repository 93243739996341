import React from 'react';
import Card from '../Services/ServicesCard'; // Assuming Card is in the same folder

const ApplicationSecurity = () => {
  const services = [
    {
      title: 'Web App Penetration Testing',
      subtitle: 'Identify vulnerabilities in web applications',
      route: '/application-security/web-app-penetration-testing',
    },
    {
      title: 'Mobile App Penetration Testing',
      subtitle: 'Ensure the security of mobile applications',
      route: '/application-security/mobile-app-penetration-testing',
    },
    {
      title: 'API Assessment',
      subtitle: 'Secure APIs with robust testing techniques',
      route: '/application-security/api-assessment',
    },
    {
      title: 'Threat Modeling',
      subtitle: 'Proactively identify potential security risks',
      route: '/application-security/threat-modeling',
    },
    {
      title: 'Secure Code Review',
      subtitle: 'Improve application security with code analysis',
      route: '/application-security/secure-code-review',
    },
    {
      title: 'Architecture Review',
      subtitle: 'Enhance security at the architecture level',
      route: '/application-security/architecture-review',
    },
  ];

  return (
     
    <div className="p-6 bg-[#121212] min-h-screen">
      <h1 className="text-2xl font-bold text-white mb-6">Application Security Services</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {services.map((service, index) => (
          <Card
            key={index}
            title={service.title}
            subtitle={service.subtitle}
            route={service.route}
          />
        ))}
      </div>
    </div>
  );
};

export default ApplicationSecurity;
