import React from 'react'
import contactuspng from "../../../images/contactus.png"
import Navbar from '../../sections/Navbar'

const ContactUs = () => {
    return (<>

        <Navbar />

        {/* Contact Us Section */}
        <section className="py-36 bg-[#0E0E0E]">
            <div className="container mx-auto px-6">
                <h2 className="text-3xl sm:text-4xl lg:text-5xl font-semibold text-left text-white mb-8">
                    Contact <span className="text-[#D1F60A]">Us</span>
                </h2>

                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                    {/* Full Width Top Section */}
                    <div
                        className="relative overflow-hidden bg-[#1D1D1D] rounded-lg p-6 sm:col-span-3 flex flex-col justify-center items-center h-72 border border-transparent hover:border-[#D1F60A] transition-all duration-300 ease-in-out transform hover:scale-[1.02] shadow-md hover:shadow-lg"
                        style={{
                            backgroundImage: `url(${contactuspng})`,
                            backgroundSize: "cover",
                            backgroundPosition: "top",
                        }}
                    >
                        {/* Overlay for better contrast */}
                        <div
                            className="absolute inset-0 z-0"
                            style={{
                                background: "linear-gradient(to top right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))",
                            }}
                        ></div>

                        {/* Bottom Left Question */}
                        <p className="absolute text-bold bottom-6 left-6 text-4xl text-white font-medium z-10">
                            Worrying about <br /> security?
                        </p>
                    </div>

                    {/* Second Row - 30% Column */}
                    <div className="relative overflow-hidden bg-[#1D1D1D] rounded-lg p-6 flex flex-col justify-center border border-transparent h-72 hover:border-[#D1F60A] transition-all duration-300 ease-in-out transform hover:scale-[1.02] shadow-md hover:shadow-lg">
                        <h3 className="absolute text-bold bottom-6 left-6 text-4xl text-white font-medium z-10">
                            +91 75693 23695
                        </h3>
                    </div>

                    {/* Second Row - 70% Column */}
                    <div className="relative overflow-auto bg-[#1D1D1D] rounded-lg p-6 flex flex-col justify-center border border-transparent h-72 hover:border-[#D1F60A] transition-all duration-300 ease-in-out transform hover:scale-[1.02] shadow-md hover:shadow-lg sm:col-span-2">
                        <div className="flex items-center mt-2 absolute text-bold bottom-6 left-6">
                            <h3 className="text-4xl text-white font-medium">info@appsecninja.in</h3>
                            <button
                                className="ml-4 p-2 bg-[#333333] text-white rounded-full hover:bg-[#D1F60A] transition-all"
                                onClick={() => navigator.clipboard.writeText("contact@appsecninja.com")}
                                title="Copy to Clipboard"
                            >
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="transition-colors duration-300"
                                >
                                    <path
                                        d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>
    )
}

export default ContactUs